import React, { useState, useEffect } from 'react';
import './Landing.css';
import Footer from './Footer'
export default function Landing() {
  const [currentYear] = useState(new Date().getFullYear());
  const [industries] = useState([
    "Super Market", "Foot Wear", "Pharmacy Retail", "Textile", "Garment", "Retail Shop", "Kirana/Grocery",
    "Departmental", "FMCG", "Pharmacy Distribution", "Electrical Appliance"
  ]);
  const [features] = useState([
    { icon: 'fas fa-mobile-alt', title: 'Mobile App', Desc: "Manage your business on the go with the Auditusierp mobile app. Keep track of your inventory, sales, and finances wherever you are. Stay connected with real-time updates and notifications, ensuring you never miss a beat." },
    { icon: 'fas fa-file-invoice', title: 'E-invoice', Desc: "Generate and manage e-invoices effortlessly with Auditusierp. Streamline your billing process, reduce errors, and ensure compliance with the latest regulations. Save time and improve efficiency with our user-friendly invoicing system." },
    { icon: 'fas fa-truck', title: 'E-waybill', Desc: "Simplify your logistics with e-waybills in Auditusierp. Create, manage, and track e-waybills effortlessly, ensuring smooth and hassle-free transportation of goods. Enhance your supply chain efficiency and reduce paperwork." },
    { icon: 'fas fa-code-branch', title: 'Multiple Branches', Desc: "Manage multiple branches effortlessly with Auditusierp. Centralize your operations, monitor performance, and ensure consistency across all locations. Gain insights and make informed decisions with comprehensive branch management features." },
    { icon: 'fas fa-laptop', title: 'Online and Offline', Desc: "Work seamlessly both online and offline with Auditusierp. Ensure uninterrupted access to your data and functionalities, even without an internet connection. Sync your data automatically once you're back online, ensuring you never lose important information." },
    { icon: 'fas fa-chart-line', title: 'Dashboards', Desc: "Get insightful dashboards with Auditusierp. Visualize your key metrics and performance indicators in real-time. Make data-driven decisions with customizable dashboards that provide a comprehensive overview of your business." },
    { icon: 'fas fa-pencil', title: 'Custom Features', Desc: "Tailor-made features for your business needs with Auditusierp. Customize the software to fit your specific requirements, ensuring maximum efficiency and productivity. Get the flexibility you need to adapt to changing business environments." },
    { icon: 'fas fa-file-import', title: 'Data Import & Export', Desc: "Simplify your data management with easy data import and export in Auditusierp. Seamlessly transfer data between different systems, ensuring accuracy and consistency. Save time and reduce errors with our efficient data management tools." },
    { icon: 'fas fa-file', title: 'Self Print Formats', Desc: "Manage your printing needs effortlessly with self-print formats in Auditusierp. Customize your print layouts to match your branding and business requirements. Ensure professional and consistent documents every time." },
    { icon: 'fas fa-cash-register', title: 'POS', Desc: "Handle transactions efficiently, track sales, and manage your inventory in real-time with the POS system in Auditusierp. Work seamlessly both online and offline to enhance your retail operations." },
    { icon: 'fas fa-boxes-stacked', title: 'Multiple Units', Desc: "Manage different units of measurement effortlessly and ensure accurate inventory tracking with Auditusierp. Simplify your operations and improve accuracy with flexible unit management." },
    { icon: 'fas fa-tag', title: 'Multiple Pricelists', Desc: "Manage different pricing strategies for various customer segments, products, or regions with Auditusierp. Enhance your pricing flexibility and maximize profitability with tailor-made features." }
  ]);
  const [formData, setFormData] = useState({
    I_am: '',
    business: '',
    name: '',
    phonenumber: '',
    email: '',
    city: '',
    pincode: '',
    message: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData();

    Object.keys(formData).forEach(key => form.append(key, formData[key]));

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbyCu0btuLiKR7PcOPfIfRif1f-QKZh91creziglHZFjEgl1l39-uRLm7j97QpTY3ETs/exec', {
        method: 'POST',
        body: form
      });
      const data = await response.json();
      if (data.result === 'success') {
        alert('Successfully submitted');
        setFormData({
          I_am: '',
          business: '',
          name: '',
          phonenumber: '',
          email: '',
          city: '',
          pincode: '',
          message: ''
        });
      } else {
        alert('Submission failed, please try again');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Submission failed, please try again');
    }
  };

  return (
    <div className="main-content">
      <div className="form-content">
        <div className="content">
          <div className="logo-container">
            <a href="/"rel="noopener noreferrer" className="logo">
              <img src="images/auditlogo.png" className="logo_img" alt="Logo" />
            </a>
          </div>
          <h3 className="con_head1">
            Unleash The Efficiency of <br />
            <span className="con_head2">Hassle-Free</span> <br />
            <span className="con_head3">Accounting!</span>
          </h3>
          <div className="img_ct">
            <img src="images/audit.jpg" alt="Logo" />
            <span className="logo_txt">#World Class Accounting Software</span>
          </div>
          <div className="price">
            <h3>Price: 13,500/- Life-Time</h3>
          </div>
        </div>
        <div className="form-container">
          <h2>Get Free Trial</h2>
          <form onSubmit={handleSubmit}>
            <select name="I_am" value={formData.I_am} onChange={handleInputChange} required>
              <option value="" disabled selected>I am</option>
              <option value="Business Owner">Business Owner</option>
              <option value="Accountant">Accountant</option>
              <option value="Student">Student</option>
              <option value="Others">Others</option>
            </select>
            <select name="business" value={formData.business} onChange={handleInputChange} required>
              <option value="" disabled selected>Select Business</option>
              <option value="Retail">Retail Business</option>
              <option value="Distribution">Distribution Business</option>
              <option value="Manufacturing">Manufacturing Business</option>
            </select>
            <input type="text" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
            <input type="text" id="phonenumber" name="phonenumber" placeholder="Mobile Number" value={formData.phonenumber} onChange={handleInputChange} required />
            <input type="email" id="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
            <input type="text" id="city" name="city" placeholder="City" value={formData.city} onChange={handleInputChange} required />
            <input type="number" id="pincode" name="pincode" placeholder="Pincode" value={formData.pincode} onChange={handleInputChange} required />
            <textarea id="message" name="message" placeholder="Write Your Message" value={formData.message} onChange={handleInputChange}></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <section className="highlight-features">
        <div className="features-container">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <h4 className="feature-title"><i className={feature.icon}></i> {feature.title}</h4>
              <p className="feature-desc">{feature.Desc}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="section_2">
        <h3 className="heading">Industry Specific Solutions</h3>
        <div className="industries">
          {industries.map((industry, index) => (
            <div key={index} className="tile">{industry}</div>
          ))}
        </div>
      </section>
    </div>
  );
}
