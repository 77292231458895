/* eslint-disable no-unused-vars */
import React,{ Component} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './NavBar.css';
import arr from '../Menu/data.json';

export default class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      showRetail: false,
      showDistribution: false,
      showManufacturing: false,
      isScrolled: false, // Add state to track scroll status
    };
    this.dropdownRef = React.createRef(); // Use createRef for class components
  }

  componentDidMount() {
    // Add a scroll event listener to track scrolling
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // Remove the scroll event listener when the component is unmounted
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // Update the isScrolled state based on the scroll position
    if (window.scrollY > 50) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };
  toggleMenu = () => {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }), () => {
    });
  };
  handleNameClick = (name) => {
    this.setState({
      selectedEdition: name,
      showMenu: false,
    });
  };
  render() {
    const { showMenu, selectedEdition } = this.state;

    // Filter and slice the "Retail" items into two separate arrays for two columns
    const retailItems = arr.filter(item => item.type === 'Retail');

    const navbarClass = this.state.isScrolled ? 'navbar scrolled' : 'navbar';
    return (
<div>
          <nav className={`navbar fixed-top ${navbarClass} navbar-expand-lg`}>
<div className="container-fluid">
      <Link to='/'  className="brd_d_l"> <img className='brd_img' src='/images/auditlogo.png' alt="Company_logo"></img> </Link>
      <button
onClick={this.toggleMenu}
className="navbar-toggler border border-info text-info menufornavbar"
type="button"
data-bs-toggle="collapse"
data-bs-target="#navbarNavAltMarkup"
aria-controls="navbarNavAltMarkup"
aria-expanded="false"
aria-label="Toggle navigation"
>
<div class="bar1"></div>
<div class="bar2"></div>
<div class="bar3"></div>
</button>

    <div
        className={`${
          showMenu ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'
        }`}
        id="navbarNavAltMarkup"
      >          <div className="navbar-nav navs ms-auto ">
  <div className='grid_layout'>

      <div  className='grid_2'>
      <Dropdown>
                    <Dropdown.Toggle className="nav-link dropdownmenunav    navs  page-scroll">
                    <span className='product_menu'> Products</span>
                    <span className='underline'></span>
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu
className={`mega-dropdown ${showMenu ? 'show' : ''}`}
ref={this.dropdownRef}
>
               <div className='su_mega_dr'>
      <div className='mg_products'>
      <div className='md_price_det'>
          <center><h6 className='f_mg_dr'>Products</h6></center>
          <hr/>
         <div className='P_tabel'>
         <div className='P_tabel_img'><img src='/images/audit.jpg' alt='' /></div>
         <div className='p_tabel_txt'>                    <Link to='/Product_Edition/Supreme'>
<h6>Auditusierp</h6>
         <p>
The Auditusierp edition is the ultimate choice for large enterprises, offering comprehensive tools for seamless financial management, including accounting, billing, inventory, and GST filing. Ideal for both multinational corporations and fast-growing startups</p>    
    </Link>

         </div>         </div>
   <div className='P_tabel'>
         <div className='P_tabel_img'><img src='/images/report.png' alt='' /></div>
         <div className='p_tabel_txt'>                   <Link to='/Product_Edition/Reportdesign'>
<h6>Report Designer</h6>
         <p>Revolutionize your reporting experience with Auditus-IERP's advanced Report Designer Tool. Unleash creativity and precision as you effortlessly design dynamic reports using a comprehensive suite of intuitive tools, ensuring your data speaks volumes in a visually compelling manner</p>     
              </Link>

         </div>     </div>
   <div className='P_tabel'>
         <div className='P_tabel_img'><img src='/images/dashborard.png' alt='' /></div>
         <div className='p_tabel_txt'>                    <Link to='/Product_Edition/Dashboard'>
<h6>Dashboard Designer</h6>
         <p>Empower decision-making with Auditus-IERP's Dashboard Designer, featuring a dynamic interface for at-a-glance insights. Monitor profits, losses, and more through customizable tools, ensuring a comprehensive view of your business performance</p>  
                 </Link>

         </div>     </div>
   </div>
      </div>
      <div className='mg_features'>
      <center>    <h6 className='f_mg_dr'>Features</h6>
</center><hr/>

      <div className='mg_feat'>
        <div className='feat_box'>
          <div className='feat_box_ic'><img alt='' src='/images/s_p_formate.png'/></div>
<div>            <h6>Self Design Print Formats</h6>         
 <p>Effortlessly customize your print formats with Auditus-IERP's self-design feature, empowering you to create professional and tailored documents in just a few clicks</p>
</div>
        </div>
        <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/bank.png'/></div>

<div>            <h6>Branch Management</h6>             <p>Simplify branch management with Auditus-IERP's intuitive feature. Seamlessly oversee multiple branches, enhance coordination, and optimize operations for streamlined success</p>
</div>
        </div>
        <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/profit.png'/></div>

<div>
<h6>Financial Accounting</h6>            <p>Empower your financial control with Auditus-IERP's robust accounting feature. Gain real-time insights, streamline transactions, and ensure precision in your financial management.</p>

</div>
        </div>
        <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/inv_man.png'/></div>

<div>            <h6>Inventory Management</h6>            <p>Optimize inventory control with Auditus-IERP's powerful management feature. Effortlessly track, manage, and streamline your inventory for enhanced efficiency and accuracy</p>
</div>
        </div>
      <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/tax.png'/></div>

<div>
<h6>GST Accounting</h6>            <p>Simplify GST compliance with Auditus-IERP's comprehensive accounting feature. Seamlessly manage GST transactions, reporting, and compliance for a hassle-free financial experience</p>

</div>
      </div>
      <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/invoice.png'/></div>

<div>
<h6>E - Invoice</h6>            <p>Effortlessly generate and manage E-invoices with Auditus-IERP's streamlined feature. Experience seamless electronic invoicing for a more efficient and modern business process</p>

</div>
      </div>
      <div className='feat_box'>           <div className='feat_box_ic'><img alt='' src='/images/filter.png'/></div>

<div>
<h6>PowerFul Report Filters</h6>            <p>Harness the power of Auditus-IERP's robust report filter for precise and insightful data analysis. Streamline your reporting with advanced filtering capabilities for unparalleled control</p>

</div>
      </div>
      </div>
      </div>
      <div className='mg_din_spec'>
      <center><h6 className='f_mg_dr'>Industry Specific Solutions</h6></center>
      <hr/>
     <div className='mg_dr_ind_spe'>
     {arr.map((item, index) => {
  if (item.type === "Retail" || item.type === "Distribution") {
    return (
<Link
to="/Productmenu"
state={{ productData: { product: item.name } }}
key={index}
onClick={() => {
  this.handleNameClick(item.name);
  // Set a state to hide the mega menu
  this.setState({ showMenu: false });
  // Optionally, scroll to the top
  window.scrollTo({ top: 0, behavior: 'smooth' });
}}
>
<h6 className="item_r_name">
  <img alt='' src={item.img} className='mg_dr_img'/>
  <span className='mega_item_name'>{item.name}</span><br/>
</h6>
</Link>


            );
          }
          return null;
        })}                      
     </div>
      </div>
  </div>

</Dropdown.Menu> 


                  </Dropdown>                     
         
                  <Link
              to='/Products'
              className="nav-link navs page-scroll"
              onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}
            >
              <span className='nav_txt'>Pricing</span>
            </Link>
            <span className='underline'></span>

            {/* Videos Link */}
            <Link
              to='/Support'
              className="nav-link navs page-scroll"
              onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}
            >
              <span className='nav_txt'>Videos</span>
            </Link>
            <span className='underline'></span>

            {/* Services Link */}
            <Link
              to='/Service'
              className="nav-link navs page-scroll"
              onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}
            >
              <span className='nav_txt'>Services</span>
            </Link>
            <span className='underline'></span>

            {/* PrintFormat Link */}
            <a
              href='http://print.auditusierp.com'
              className="nav-link navs page-scroll"
              onClick={(e) => {
                e.preventDefault();
                window.open('http://print.auditusierp.com', '_blank');
              }}
            >
              <span className='nav_txt'>PrintFormat</span>
            </a>
            <span className='underline'></span>

            {/* Contact Link */}
            <Link
              to='/Contact'
              className="nav-link navs page-scroll"
              onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}
            >
              <span className='nav_txt'>Contact</span>
            </Link>
            <span className='underline'></span>

            {/* Download Link */}
            <Link
              to='/Download'
              className="nav-link navs page-scroll"
              onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}
            >
              <span className='nav_txt'>Download</span>
            </Link>
                                  <span className='underline'></span>
         {/* <div className="contact_p"    onClick={() => { this.setState({ show: true }); }}    >Login</div> */}
                                  
          </div>
  </div>
          </div>

          </div>

    </div>
</nav>     
</div>
    )
  }
}