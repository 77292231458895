/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import {useParams,useLocation  } from 'react-router-dom';
import Productslist from './data.json';
// import Banner from '../Banner/Banner';


function Productmenu() {

  const {type} =useParams();  
  const location = useLocation();
  const { productData } = location.state && location.state.productData ? location.state : { productData:{product: " "} };
  let product_slide;
 let filteredArray = Productslist.filter(function(obj) {
 
  if (obj.name === productData.product) {
    return true;
  }
}).map(function(obj) {
  product_slide = obj.Product_Detials[0].product_sliderimgs

  return obj.Product_Detials[0];


});

  return (
      <div className='data_container'>
     {/* <Banner passData= {product_slide} />  */}

<ul><br/>
<center>
{filteredArray && filteredArray.length > 0 && (
  <ul>
    {filteredArray.map((p) => (
      <li className="productmenu" key={p.id}>
        <p className='product-title'>{p.Product_title}</p>
        <p className='product-title-dec'>{p.product_dec}</p>
        <iframe className='sp_vid' alt src={p.video}  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen/><br/>
     <br/>   <center>
        {p.productlist && p.productlist.length > 0 && (
  <ul>
    {p.productlist.map((sp, index) => (
      <li className='sub_menu_products' key={index}>
        <div className='sub_menu_main_d' style={{
          display: 'flex',
          flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
          backgroundColor: index % 2 === 0 ? '#FFC032' : '#dbe9f4', // Background color based on index
        }}>
          <div>
            <center>            <img alt='' className='sp_sub_img' src={sp.sp_img} />
</center>
          </div>
          <div className='pro_menu_deta' style={{ width: index % 2 === 0 ? '100%' : '100%' }}>
            <p className='sp_title'> {sp.sp_Title}</p>
            <p className='product-sp-dec'> {sp.sp_dec}</p>
          </div>
        </div>
      </li>
    ))}
  </ul>
)}
        </center>

      </li>
    ))}
  </ul>
)}
</center>

</ul>
</div>
  );

  
}

export default Productmenu;



