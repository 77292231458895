import React from 'react';
import './Download.css';
import demos from './Specification.json'

export default function Download() {
  var Downloadfiles = [
    {
      title: 'Auditsierp18.5.5.9',
      Download_link: '/downloads/Auditusierp18.5.5.9.rar',
      Date: '2023-11-01',
    },
    {
      title: 'Mysql 8.0.33',
      Download_link: '/downloads/mysql 8.0.31.rar',
      Date: '2023-05-19',
    },
    {
      title: 'AuditusierpReportTool',
      Download_link: '/downloads/AuditusierpReportTool 18.5.5.9.rar',
      Date: '2023-11-01',
    },
    {
      title: 'AudiutsierpDashboard',
      Download_link: '/downloads/Consignment.rar',
      Date: '2023-05-19',
    }
  ];
  const GSTReports = [
    {
      title: 'GSTR 1',
      Download_link: '/gstreports/GSTR1.zip',
    },
    {
      title: 'GSTR 2',
      Download_link: '/gstreports/GSTR2.zip',
    },
    {
      title: 'GSTR 3b',
      Download_link: '/gstreports/GSTR3B.zip',
    },
    {
      title: 'GSTR 4',
      Download_link: '/gstreports/GSTR4.zip',
    },
    {
      title: 'GSTR ITC04',
      Download_link: '/gstreports/GSTRITC04.zip',
    },
  ];

  return (
    <div className='download-body'>
     <div>
     <center>
        <h3 className='download-title'>Downloads</h3>
      </center>
      <table className='table'>
        <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {Downloadfiles.map((value, index) => (
            <tr key={index}>
              <td>{value.title}</td>              

              <td>{value.Date}</td>
              <td>
                <a
                  href={value.Download_link}
                  className='down_but'
                  role='button'
                  aria-pressed='true'
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     </div>
     <section className='indus_spefic_data'>
      <div className='tabel_ind'>
        <h3 className='spec_tit'>Industrial Specifications</h3>
    <table>
        <thead>
            <tr>
                <th>Template</th>
                <th>Download</th>
            </tr>
        </thead>
        <tbody>
        {demos.map((item, index) => (
    <tr key={index}>
      <td className='name_down'>{item.title}</td>
      <td>
<a href={encodeURIComponent(item.Download_link)} className='download_link_ind'>
  Click to Download
</a>

      </td>
    </tr>
  ))}
        </tbody>
    </table>

      </div>
     </section>
     <section className='reportSec'>
        <div className='tabel_ind'>
          <h3 className='spec_tit'>GST Reports</h3>
          <table>
            <thead>
              <tr>
                <th>Template</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {GSTReports.map((report, index) => (
                <tr key={index}>
                  <td className='name_down'>{report.title}</td>
                  <td>
                    <a href={report.Download_link} className='download_link_ind'>
                      Click to Download
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}
