import React from 'react'
import './Service.css'

export default function Service2() {
  return (
    <div>
         <section className='servicesec'>

      <div class="row">
        <div class="columnss">
          <div class="cardss">
            <div class="icon-wrapper">
            <i className="fa-solid fa-pencil"></i>
            </div>
            <h3>Customized solutions</h3>
            <p>
            Auditus-iERP provides flexible and customizable software, ensuring your business stays competitive. With expert support, navigate changes and thrive efficiently. Invest in Auditus-iERP for a solution that maximizes your potential and keeps you ahead in the business world
            </p>
          </div>
        </div>
        <div class="columnss">
          <div class="cardss">
            <div class="icon-wrapper">
          <i className="fa-solid fa-code"></i>

            </div>
            <h3>Integration solutions</h3>
            <p>
         
Auditus-iERP transforms business operations by seamlessly streamlining processes like marketing and HR, boosting efficiency. Its advanced integration minimizes errors, centralizes data, and empowers data-driven decisions. Auditus-iERP stands as a key solution for long-term success in modern business
            </p>
          </div>
        </div>
        <div class="columnss">
          <div class="cardss">
            <div class="icon-wrapper">
            <i className="fa-sharp fa-solid fa-gear"></i>
            </div>
            <h3>Technical support</h3>
            <p>
            Auditus-iERP prioritizes customer satisfaction, offering comprehensive technical support via email and telephone. Our skilled team is dedicated to timely and reliable assistance, ensuring confidence in product selection. With Auditus-iERP's expert support, customers can invest with peace of mind for a seamless experience            </p>
          </div>
        </div>
        <div class="columnss">
          <div class="cardss">
            <div class="icon-wrapper">
            <img alt='Training' src='/images/gear.png' />          </div>
            <h3>Training</h3>
            <p>
            Unlock the full potential of Auditusierp with our comprehensive training programs designed to empower individuals and businesses in harnessing the capabilities of this cutting-edge software</p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}