/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Support.css';

const API_KEY = 'AIzaSyBym_JcVaL4aSw8ZFmFBGuVxmVF1PoDO7E'; // Replace with your actual API key
const CHANNEL_ID = 'UCbCMsf2oYMY6qYFJ4vHrmJg';

export default function Support() {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    if (searchInput.trim() === '') {
      setFilteredVideos(videos); // Show all videos if the search input is empty
    } else {
      const searchQuery = searchInput.trim().toLowerCase();
      const filtered = videos.filter((video) =>
        video.title.toLowerCase().includes(searchQuery)
      );
      // Sort the filtered videos to show the best match first
      const sorted = filtered.sort((a, b) =>
        a.title.toLowerCase().indexOf(searchQuery) -
        b.title.toLowerCase().indexOf(searchQuery)
      );
      setFilteredVideos(sorted);
    }
  }, [searchInput, videos]);

  const fetchVideos = async () => {
    try {
      const videoDetails = await fetchVideoDetails();

      setVideos(videoDetails);
      setFilteredVideos(videoDetails); // Initialize filteredVideos with all videos
    } catch (error) {
      setError(error.message || 'Error fetching YouTube videos');
    }
  };
  const fetchVideoDetails = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=id&order=date&maxResults=50&type=video`
      );
      const data = await response.json();
  
      if (data.items) {
        const videoIds = data.items.map((item) => item.id.videoId);
        const videoDetails = await fetchVideoDetailsByIds(videoIds);
  
        // Separate the videos into two arrays based on duration
        const fullVideos = videoDetails.filter((video) => video.duration >= 60);
        const shortVideos = videoDetails.filter((video) => video.duration < 60);
  
        // Concatenate the two arrays, with fullVideos appearing first
        const sortedVideos = [...fullVideos, ...shortVideos];
  
        return sortedVideos;
      } else {
        throw new Error('No videos found in the API response');
      }
    } catch (error) {
      throw new Error('Error fetching YouTube video details: ' + error.message);
    }
  };
  
  
  const fetchVideoDetailsByIds = async (videoIds) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?key=${API_KEY}&id=${videoIds.join(',')}&part=snippet,contentDetails`
      );
      const data = await response.json();

      if (data.items) {
        return data.items.map((item) => ({
          id: item.id,
          title: item.snippet.title,
          thumbnail: item.snippet.thumbnails.medium.url,
          duration: parseDuration(item.contentDetails.duration),
        }));
      } else {
        throw new Error('No video details found in the API response');
      }
    } catch (error) {
      throw new Error('Error fetching YouTube video details: ' + error.message);
    }
  };

  const parseDuration = (durationString) => {
    const matches = durationString.match(/PT(\d+)M(\d+)S/);
    if (matches) {
      const minutes = parseInt(matches[1]);
      const seconds = parseInt(matches[2]);
      return minutes * 25 + seconds;
    }
    return 0;
  };
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
   <section className='supsec'>
      <div className='supportbody'>
        <div className='supportcontainer'>
          <h1>Auditusierp Support</h1>
          {/* search box */}
          <center>
            <div className='search-container'>
              <input
                type='text'
                placeholder='Search...'
                value={searchInput}
                onChange={handleSearchInputChange} // Update the searchInput state
              />
            </div>
          </center>
 <center>
 <div className='video-container'>
            {filteredVideos.length > 0 ? (
              filteredVideos.map((video) => (
                <div key={video.id} className='video'>
                 <center> <a
                    href={`https://www.youtube.com/watch?v=${video.id}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={video.thumbnail}
                      alt={video.title}
                      className='vid_img'
                    />
                  </a>
                  <div className='videodescription'>
                    <p className='video_d'>{video.title}</p>
                  </div></center>
                </div>
              ))
            ) : (
              <p>No videos found</p>
            )}
          </div>
 </center>
        </div>
      </div>
      </section>
    </div>
  );
}