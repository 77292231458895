import React from "react";
// import Menulist from './Menulist';
import { useLocation } from 'react-router-dom';
import './Menu.css';
import Features from "../Features/Features"; // Import the Features component

export default function Home() {
  const location = useLocation();

  return (
    <div>
      <Features /> {/* Render the Features component by default */}
      <div className={location.state?.data?.name}>
        {/* <Menulist passData={location.state?.data?.name} /> */}
      </div>
    </div>
  );
}
