import React, { Component } from 'react';
import './Contact.css';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 'auto',
      setResponse: null,
      modalOpen: false,
      formData: {
        name: '',
        email: '',
        phonenumber: '',
        city: '',
        pincode: '',
        bussiness: '',
        I_am: '',
        message: ''
      }
    };

    this.textareaRef = React.createRef();
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    console.log('Closing modal');
    this.setState({ modalOpen: false, setResponse: null });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const submitButton = event.target.querySelector('.contact-button');
    submitButton.setAttribute('disabled', 'true');
    submitButton.classList.add('loading');

    const formData = new FormData(event.target);
    const scriptURL =
      'https://script.google.com/macros/s/AKfycbyCu0btuLiKR7PcOPfIfRif1f-QKZh91creziglHZFjEgl1l39-uRLm7j97QpTY3ETs/exec';

    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        this.setState({ setResponse: 'Successfully' });

        setTimeout(() => {
          event.target.reset();
          submitButton.removeAttribute('disabled');
          submitButton.classList.remove('loading');
          this.openModal();
        }, 100);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      this.setState({ setResponse: 'Error' });
      submitButton.removeAttribute('disabled');
      submitButton.classList.remove('loading');
    }
  };

  render() {
    const { setResponse } = this.state;
    return (
      <div className="contact-page contact-container">
        <div className="contact-container">
          <div className="container-fluid">
            <div className="contact_col1">
              <div className="contact-description">
                <h3 className="contact-title">{this.props.name}</h3>
                <h6>
                  Auditus-IERP is considered one of the best software solutions
                  for businesses in various industries offering a range of
                  features to help manage and streamline business operations
                </h6>
                {/* <div className="details_c">
                  <h3>Contact: 9032032612</h3>
                </div> */}
              </div>
            </div>
            <div className="contact-container">
              <form
                method="POST"
                className="contact-form"
                name="google-sheet"
                onSubmit={this.handleSubmit}
              >
                <div className="main_f">
                  <input
                    className="form-control contact-inputs"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name"
                    value={this.state.formData.name}
                    onChange={this.handleChange}
                    required
                  />

                  <div className="contactdropdown">
                    <select
                      required
                      name="bussiness"
                      className="form-select contact-inputss"
                      aria-label="your what bussiness or accountant or student or others"
                      value={this.state.formData.bussiness}
                      onChange={this.handleChange}
                    >
                      <option value="Retail">Retail Business</option>
                      <option value="Distribution">Distribution Business</option>
                      <option value="Manufacturing">Manufacturing Business</option>
                    </select>
                  </div>

                  <div className="contactdropdown">
                    <select
                      required
                      name="I_am"
                      className="form-select contact-inputss"
                      aria-label="your what bussiness or accountant or student or others"
                      value={this.state.formData.I_am}
                      onChange={this.handleChange}
                    >
                      <option value="Bussiness Owner">I am Business Owner</option>
                      <option value="Accountant">I am Accountant</option>
                      <option value="Student">I am Student</option>
                      <option value="Others">I am Others</option>
                    </select>
                  </div>

                  <input
                    className="form-control contact-inputs"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={this.state.formData.email}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    className="form-control contact-inputs"
                    type="tel"
                    id="number"
                    name="phonenumber"
                    placeholder="Enter Your Mobile-Number"
                    pattern="[0-9]{10}"
                    value={this.state.formData.phonenumber}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    className="form-control contact-inputs"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter Your City"
                    value={this.state.formData.city}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    className="form-control contact-inputs"
                    type="number"
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Your Pincode"
                    value={this.state.formData.pincode}
                    onChange={this.handleChange}
                    required
                  />

                  <textarea
                    ref={this.textareaRef}
                    style={{ height: this.state.height }}
                    className="form-control contact-textarea"
                    id="message"
                    name="message"
                    placeholder="Write Your Message"
                    value={this.state.formData.message}
                    onChange={this.handleChange}
                  ></textarea>

                  <center>
                    <button className="contact-button" type="submit">
                      Send
                    </button>
                  </center>
                </div>
              </form>
              {setResponse && (
                <div className="custom-modal">
                  <div className="modal-content">
                    <div>
                      <img src="/images/audit.jpg" alt="" />
                    </div>
                    <div>
                      <span className="close" onClick={this.closeModal}>
                        &times;
                      </span>
                      <p>Your message has been {setResponse} saved</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
