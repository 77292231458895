import './App.css';
import React from 'react';
import Contact from './components/Contact/Contact';
import Navbar from './components/NavBar/Navbar';
import Products from './components/Price/Products';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Service from './components/Service/Service';
import Download from './components/Download/Download';
import Home from './components/Menu/Home';
import { Startup, Standard, Supreme, Reportdesign, Dashboard } from './components/Products/Product_Edition';
import Footer from './components/Footer';
import Edition from './components/Price/Edition';
import Banner from './components/Banner/Banner';
import Productmenu from './components/Menu/Productmenu';
import Support from './components/Support/Support';
import Features from './components/Features/Features';
import Landing from './components/Landing';
import { Helmet } from 'react-helmet';

function App() {
     return (
          <div>
               <Helmet>
                    <title>Auditus-iERP</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <meta name="description" content="Elevate your financial management with our cutting-edge accounting software. Streamline operations with precision and efficiency. Gain real-time insights for informed decisions. Experience the perfect blend of power and simplicity. Choose the industry’s best for unparalleled performance." />
                    <meta name="keywords" content="accounting software, financial management, business software, bookkeeping, invoicing, expense tracking, payroll software, tax software, budgeting, financial reporting, audit software, inventory management, billing software, accounting solutions, financial insights" />
               </Helmet>
               <BrowserRouter>
                    <Navbar />
                    <div className="App">
                         <Helmet>
                              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-326702791"></script>
                              <script>
                                   {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-326702791');
              `}
                              </script>
                         </Helmet>
                         <Routes>
                              <Route path='/' element={<Home />} />
                              <Route path='/Products' element={<Products />} />
                              <Route path='/Contact' element={<Contact />} />
                              <Route path='/Service' element={<Service />} />
                              <Route path='/Features' element={<Features />} />
                              <Route path='/Download' element={<Download />} />
                              <Route path='/Edition' element={<Edition />} />
                              <Route path='/Product_Edition/Startup' element={<Startup />} />
                              <Route path='/Product_Edition/Standard' element={<Standard />} />
                              <Route path='/Product_Edition/Supreme' element={<Supreme />} />
                              <Route path='/Product_Edition/Reportdesign' element={<Reportdesign />} />
                              <Route path='/Product_Edition/Dashboard' element={<Dashboard />} />
                              <Route path='/Banner' element={<Banner />} />
                              <Route path='/Productmenu' element={<Productmenu />} />
                              <Route path='/Support' element={<Support />} />
                              <Route path='/land' element={<Landing />} />
                         </Routes>
                    </div>
                    <Footer />
               </BrowserRouter>
          </div>
     );
}

export default App;
