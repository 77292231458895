// import React from 'react'
// import {a} from 'react-router-dom'

// export default function Footer() {
//   return (
//     <div className='Footer'>
//       <div className='f_top'>
//         <span> <img className='f_logo' src='/images/logo2.jpg' alt=""/></span>
//       </div>
//       <div className='f_bot'>
//         <div className='f_links'>
//           <li  className='h_l'>as</li>
//           <a className='f_m_link' to='/Products'>Pricing</a><br/>
//           <a className='f_m_link' to='/Features'>Features</a><br/>
//           <a className='f_m_link' to='/Support'>Videos</a><br/>
//           <a className='f_m_link' to='/Service'>Services</a><br/>
//           <a className='f_m_link' to='/Download'>Download</a><br/>
//           <a className='f_m_link' to='/Contact'>Contact</a>
//         </div>
//         <div className='f_services'>
//         <li className='h_l'>Our Services</li>
//           <span className='f_m_service'>Customized Solutions</span><br/>
//           <span className='f_m_service'>Integration Soluations</span><br/>
//           <span className='f_m_service'>Technical Soluations</span>
//         </div>
//         <div className='f_price'>
//         <li className='h_l'>Price List</li>
//           <span className='f_m_price'>Startup Single ₹3600</span><br/>
//           <span className='f_m_price'>Standard Single User ₹6300</span><br/>
//           <span className='f_m_price'>Supreme Single User ₹9000</span><br/>
//           <a className='f_mm' to='/Products'>
//         ➜For More
// </a>
//         </div>
//         <div className='f_details'>
//         <li className='h_l'>Contact Details</li><br/>
//           <span className='f_m_details'>Contact Number : 90-3203-2612</span><br/>
//           <span className='f_m_details'>Address :  Telangana  ,  Suryapet   , Sri Sri Nagar</span>
//         </div>
//       </div>
//     </div>
//   )
// }


import React from 'react'
export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div>
<footer>
<div className='footcontainer'>
      <div className='sec aboutus'>
        <h2><img alt='Company_logo' src='/images/auditlogo.png' /></h2>
        <p>Unlock business efficiency with Auditus-iERP, a powerful software simplifying your operations. Streamline processes, enhance productivity, and elevate your business to new heights with our intuitive solution</p>
        <ul className='sci'>
        <li><a href='https://www.facebook.com/profile.php?id=100068375949471' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook"></i></a></li>
         {/* <li> <a href='/'><i class="fa-brands fa-twitter"></i> </a></li> */}
      <li>    <a href='https://www.instagram.com/auditusierp/' target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram"></i> </a></li>
        <li>  <a href='https://www.youtube.com/@auditus-ierp3319/videos' target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-youtube"></i></a></li>
       </ul>
      </div>
      <div className='sec implink quicklinks'>
        <h2>Links</h2>
        <ul>
         <li><a href='/'>Pricing</a></li>
         <li><a href='/'>Service</a></li>
         <li><a href='/'>Videos</a></li>
         <li><a href='/'>Contact</a></li>
        </ul>
      </div>
      <div className='sec quicklinks'>
        <h2>Pricing</h2>
        <ul>
           <li><a href='/'>Auditus-iERP Single-User ₹25,500/-</a></li>
           <li><a href='/'>Auditus-iERP Dual-User ₹43,750/-</a></li>
           <li><a href='/'>Auditus-iERP Multi-User ₹75,000/-</a></li>
        </ul>
      </div>
      <div className='sec contact'>
      <h2>Contact us</h2>
      <ul className='info'>
        <li>
          <span><i class="fa-solid fa-phone"></i></span><p><a href='tel:+91 9032032612'>+91 9032032612</a></p>
        </li>
        <li>
          <span><i class="fa-solid fa-envelope"></i></span><p><a href='mailto:auditusierp@gmail.com'>auditusierp@gmail.com</a></p>
        </li>
      </ul>
      </div>
    </div>
</footer>
<div className='copytightText'>
<p>Copyright {currentYear} IPCA SOFTWARE SOLUTIONS PVT.LTD. All Rights Reserved</p>
</div>
    </div>
  )
}
