import React from 'react';
import './Product_Edition.css';
import productEditionData from './Product_editions.json';

export const Startup = () => {
  const expressEdition = productEditionData.editions.Startup;


  const {  editionFeatures } = expressEdition;
  return (
<div>
      <section className='pro_editon'>
  
        <div className='edition_features'>
          <h3>{editionFeatures.title}</h3>
          <div className='editon_feat_sub_con'>
            {editionFeatures.features.map((feature, index) => (
              <div className='su_con_box' key={index}>
                <h6>{feature.title}</h6>
                <p>{feature.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export const Standard = () => {
  const expressEdition = productEditionData.editions.Standard;

  const { editionFeatures } = expressEdition;
  return (
<div>
      <section className='pro_editon'>
    
        <div className='edition_features'>
          <h3>{editionFeatures.title}</h3>
          <div className='editon_feat_sub_con'>
            {editionFeatures.features.map((feature, index) => (
              <div  className='su_con_box' key={index}>
                <h6>{feature.title}</h6>
                <p>{feature.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
export const Supreme = () => {
  const expressEdition = productEditionData.editions.Supreme;


  const {  editionFeatures } = expressEdition;
  return (
<div>
      <section className='pro_editon'>
    
        <div className='edition_features'>
          <h3>{editionFeatures.title}</h3>
          <div className='editon_feat_sub_con'>
            {editionFeatures.features.map((feature, index) => (
              <div  className='su_con_box' key={index}>
                <h6>{feature.title}</h6>
                <p>{feature.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
export const Reportdesign = () => {
  const expressEdition = productEditionData.editions.Report_design;


  const { editionFeatures } = expressEdition;
  return (
<div>
      <section className='pro_editon'>
     
        <div className='edition_features'>
          <h3>{editionFeatures.title}</h3>
          <div className='editon_feat_sub_con'>
            {editionFeatures.features.map((feature, index) => (
              <div  className='su_con_box' key={index}>
                <h6>{feature.title}</h6>
                <p>{feature.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export const Dashboard = () => {
  const expressEdition = productEditionData.editions.Dashboard_design;
  const {  editionFeatures } = expressEdition;
  return (
<div>
      <section className='pro_editon'>
        <div className='edition_features'>
          <h3>{editionFeatures.title}</h3>
          <div className='editon_feat_sub_con'>
            {editionFeatures.features.map((feature, index) => (
              <div  className='su_con_box' key={index}>
                <h6>{feature.title}</h6>
                <p>{feature.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}