import React, { useState } from 'react';
import './Products.css';

const productRates = {
  "India": {
    "Single-user": {
      "details": [
        {
          "value": '25,500',
          "label_txt": "One-User Only Software for Standalone Computers",
          "GST_txt": "+18% GST",
          "GST_value": "4,500"
        }
      ],
      "note": "Perpetual",
    },
    "Dual-user": {
      "details": [
        {
          "value": '43,750',
          "label_txt": "LAN-Compatible Software for Two Independent Computers",
          "GST_txt": "+18% GST",
          "GST_value": "7,875"
        }
      ],
      "note": "Perpetual"
    },
    "Multi-user": {
      "details": [
        {
          "value": '75,000',
          "label_txt": "Multi-User Unlimited LAN Edition",
          "GST_txt": "+18% GST",
          "GST_value": "13,500"
        }
      ],
      "note": "Perpetual"
    },
    "mobile": {
      "details": [
        {
          "value": '13,500',
          "label_txt": "One-User Mobile App",
          "GST_txt": "+18% GST",
          "GST_value": "2,430"
        }
      ],
      "note": "Yearly"
    },
    "cloudStorage": {
      "details": [
        {

          "value": '8,100',
          "label_txt": "One Database, 5 GB Cloud Storage Space",
          "GST_txt": "+18% GST",
          "GST_value": "1,458"
        },
      ],
      "note": "Yearly"
    }
  },
  "SouthAsia": {
    "Single-user": {
      "details": [
        {
          "value": '150',
          "label_txt": "One-User Only Software for Standalone Computers",
          // "GST_txt": "Excula tax",
          // "GST_value": "2,610"
        }
      ],
      "note": "Yearly",
    },
    "Dual-user": {
      "details": [
        {
          "value": '250',
          "label_txt": "LAN-Compatible Software for Two Independent Computers",
          // "GST_txt": "+18% GST",
          // "GST_value": "4,410"
        }
      ],
      "note": "Yearly"
    },
    "Multi-user": {
      "details": [
        {
          "value": '330',
          "label_txt": "Multi-User Unlimited LAN Edition",
          // "GST_txt": "+18% GST",
          // "GST_value": "6,840"
        }
      ],
      "note": "Yearly"
    },
  },
  "Global": {
    "Single-user": {
      "details": [
        {
          "value": '226',
          "label_txt": "One-User Only Software for Standalone Computers",
          // "GST_txt": "+18% GST",
          // "GST_value": "36"
        }
      ],
      "note": "Yearly",
    },
    "Dual-user": {
      "details": [
        {
          "value": '350',
          "label_txt": "LAN-Compatible Software for Two Independent Computers",
          // "GST_txt": "+18% GST",
          // "GST_value": "63"
        }
      ],
      "note": "Yearly"
    },
    "Multi-user": {
      "details": [
        {
          "value": '663',
          "label_txt": "Multi-User Unlimited LAN Edition",
          // "GST_txt": "+18% GST",
          // "GST_value": "99"
        }
      ],
      "note": "Yearly"
    },
  }
};

export default function Products() {
  const [region, setRegion] = useState('India');

  const getCurrencySymbol = (region) => {
    switch (region) {
      case 'India':
        return '₹';
      case 'SouthAsia':
      case 'Global':
        return '$';
      default:
        return '₹';
    }
  };

  return (
    <div className='product-container'>
      <div className='price-dropdown-container'>
        <label>Choose your Location </label>
        <select onChange={e => setRegion(e.target.value)} className='price_dropdown' value={region}>
          <option value="India">India</option>
          <option value="SouthAsia">South Asia</option>
          <option value="Global">Global</option>
        </select>
      </div>
      <div className='product-boxes'>
        {Object.entries(productRates[region]).map(([productType, { details, note }]) => (
          <div key={productType} className={`product-box ${productType}`}>
            <h2>{productType.charAt(0).toUpperCase() + productType.slice(1)}</h2>
            {details.map((detail, index) => (
              <div key={index}>
                <span className='label_txt'>
                  {detail.label_txt}
                </span>
                <p className="note">({note})</p>
                <p>
                  <span>{getCurrencySymbol(region)}{detail.value}</span>
                </p>
                {detail.GST_txt && detail.GST_value && (
                  <span className='Gst_txt'>{detail.GST_txt} ({getCurrencySymbol(region)}{detail.GST_value})</span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
